import React from "react";
import CardDownloadsComponent from "../../components/card/CardDownloadsComponent";
import {inject, observer} from "mobx-react";
import browserHistory from "../../helper/browserHistory";
import config from "../../config/main.config";
import userStore from "../../stores/userStore";
import {getTokenFromLocalStorage} from "../../helper/util";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
//import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";

const PAGE_LINK = "Downloads";

@inject("stores")
@observer
class DownloadPage extends React.Component {

    constructor(props) {
        super(props);
        if (props.staticContext) {
            this.state = {
                downloads: [],
                textStore: props.staticContext,
                isLoading: true
            }
        } else {
            this.state = {
                downloads: [],
                textStore: {},
                isLoading: true
            }
        }
    }

    componentDidMount() {
        this.props.stores.trackingStore.addVisit(PAGE_LINK);
        fetch(config.BASE_URL + 'blog/download/download-page', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(json => {
                if (json.hasOwnProperty("downloads")) {
                    json.downloads.forEach(function (element) {
                        element.edit = false;
                    });
                    this.setState({...this.state, downloads: json.downloads});
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
        window.scrollTo(0, 0);
    }

    handleDownloadInputChange(type, index, event) {
        let newDownloadState = this.state.downloads;
        switch (type) {
            case 'title':
                newDownloadState[index].title = event.target.value;
                this.setState({...this.state, downloads: newDownloadState});
                break;
            case 'link':
                newDownloadState[index].link = event.target.value;
                this.setState({...this.state, downloads: newDownloadState});
                break;
            default:
                break;
        }
    }

    addArticleDownload() {
        this.state.downloads.push({
            id: "",
            title: "",
            link: "",
            edit: true
        })
        this.setState({...this.state});
    }

    uploadLink(index, event) {
        let self = this;
        const data = new FormData();
        data.append('pdf', event.target.files[0]);
        fetch(config.BASE_URL + 'blog/pdf', {
            method: "POST",
            body: data,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    console.log("response json: " + json.toString());
                    let newDownloadState = self.state.downloads;
                    newDownloadState[index].link = json.link;
                    self.setState({...self.state, downloads: newDownloadState});
                });
            }
        }).catch(() => {
        });
    }

    deleteDownload(index) {
        if (this.state.downloads[index].id !== "") {
            let self = this;
            fetch(config.BASE_URL + 'blog/download', {
                method: "DELETE",
                body: JSON.stringify({id: self.state.downloads[index].id}),
                headers: {
                    "Authorization": "Bearer " + getTokenFromLocalStorage(),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        self.state.downloads.splice(index, 1);
                        self.setState({...self.state});
                    });
                }
            }).catch(() => {
            });
        } else {
            this.state.downloads.splice(index, 1);
            this.setState({...this.state});
        }
    }

    addDownload(index) {
        let newDownloadState = this.state.downloads;
        let self = this;
        let method = "POST";
        if (this.state.downloads[index].id !== "")
            method = "PUT";

        let downloadToSend = this.state.downloads[index];
        downloadToSend.ArticleId = "download-page";
        fetch(config.BASE_URL + 'blog/download', {
            method: method,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({download: downloadToSend})
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    newDownloadState[index].edit = false;
                    newDownloadState[index].id = json.id;
                    self.setState({...self.state, downloads: newDownloadState});
                });
            }
        }).catch(() => {
        });
    }

    render() {

        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        let displayType = userStore.userFromServer !== null ? "admin" : "user";

        let showDownloads = null;

        if (displayType === "admin") {
            showDownloads = this.state.downloads.map((download, index) =>
                <Grid.Column width={12} key={index}>
                    {download.edit ?
                        <Segment>
                            <Form>
                                <Form.Group>
                                    <Form.Field width={16}>
                                        <label>Title</label>
                                        <input
                                            value={download.title}
                                            onChange={this.handleDownloadInputChange.bind(this, 'title', index)}/>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={16}>
                                        <label>Link</label>
                                        <input className={this.linkWrong ?
                                            "input-flied-wrong thumbnail-form" :
                                            "input-flied thumbnail-form"}
                                               disabled={true}
                                               value={download.link}
                                               type="text"
                                               id="inputHeader"
                                               placeholder="URL"
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label className={"transparent-text"}>. </label>
                                        <div className="no-padding thumbnail-form">
                                            <input type="file" id={"pdffile" + index}
                                                   className="inputfile thumbnail-form"
                                                   onChange={this.uploadLink.bind(this, index)}
                                                   accept=".pdf, .PDF"/>
                                            <b>
                                                <label htmlFor={"pdffile" + index}
                                                       className="call-to-action big-call-to-action thumbnail-form login-container">
                                                    HOCHLADEN
                                                </label>
                                            </b>
                                        </div>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field>
                                        <Button type='submit' positive
                                                onClick={() => this.addDownload(index)}>
                                            Speichern
                                        </Button>
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Segment>
                        :
                        <Segment>
                            <h3>{download.title}</h3>
                            <p>{download.link}</p>
                            <Button.Group>
                                <Button positive onClick={() => {
                                    let newDownloadState = this.state.downloads;
                                    newDownloadState[index].edit = true;
                                    this.setState({...this.state, downloads: newDownloadState});
                                }}>
                                    Bearbeiten
                                </Button>
                                <Button negative onClick={() => this.deleteDownload(index)}>
                                    Löschen
                                </Button>
                            </Button.Group>
                        </Segment>
                    }
                </Grid.Column>
            )
        } else {
            showDownloads = this.state.downloads.map((download, index) =>
                <CardDownloadsComponent
                    key={index}
                    title={download.title}
                    link={download.link}
                />
            );
        }

        return (
            <Grid>
                <TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'download'}
                    imageLinkId={"downloads-title-image-id"}
                    headerId={"downloads-title-header-id"}
                    subheaderId={"downloads-subtitle-header-id"}
                    small
                />
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section className={"active-breadcrumb"}>DOWNLOADS</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2><EditSimpleValueComponent textStore={this.state.textStore}
                                                                                 id={"downloads-title-sector-1-id"}
                                                                                 namespace={'download'}/></h2>
                    </Grid.Column>
                </Grid.Row>
                {displayType === "admin" ?
                    <Grid.Column width={16}>
                        <Button circular color='blue' icon='add' floated={"left"}
                                onClick={() => this.addArticleDownload()}/>
                    </Grid.Column>
                    :
                    null}
                {showDownloads}
            </Grid>
        );
    }
}

export default DownloadPage